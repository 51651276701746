import Cookie from "cookie-universal"

class CookieManager {
  #cookies

  constructor() {
    this.#cookies = Cookie()
  }

  get(cookieName) {
    return this.#cookies.get(cookieName)
  }

  set(cookieName, cookieValue) {
    const isDevelopment = process.env.NODE_ENV !== "production"

    this.#cookies.set(cookieName, cookieValue, {
      path: "/",
      maxAge: 60 * 60 * 24, // 1 day
      httpOnly: false,
      domain: isDevelopment ? undefined : ".wealthyhood.com",
      sameSite: "strict",
      secure: !isDevelopment,
    })
  }
}

export default CookieManager
