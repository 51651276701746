class EventEmitter {
  constructor() {
    this.callbacks = {}
  }

  on(event, callback) {
    if (!this.callbacks[event]) {
      this.callbacks[event] = []
    }
    this.callbacks[event].push(callback)
  }

  emit(event, data) {
    if (!this.callbacks[event]) {
      return
    }
    this.callbacks[event].forEach(callback => callback(data))
  }
}

export default EventEmitter
